import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';

const IeltsWritingTask1_EvaluatePage = lazy(() => import('./writing/evaluate-task-1/page'));
const IeltsWritingTask2_EvaluatePage = lazy(() => import('./writing/evaluate-task-2/page'));
const IeltsWritingTask1_ModifyEvaluationPage = lazy(() => import('./writing/evaluate-task-1/[id]/modify/page'));
const IeltsWritingTask2_ModifyEvaluationPage = lazy(() => import('./writing/evaluate-task-2/[id]/modify/page'));

const IeltsSpeakingPart1_EvaluatePage = lazy(() => import('./speaking/evaluate-part-1/page'));
const IeltsSpeakingPart2_EvaluatePage = lazy(() => import('./speaking/evaluate-part-2/page'));
const IeltsSpeakingPart3_EvaluatePage = lazy(() => import('./speaking/evaluate-part-3/page'));
const IeltsSpeakingPart1_ModifyEvaluationPage = lazy(() => import('./speaking/evaluate-part-1/[id]/modify/page'));
const IeltsSpeakingPart2_ModifyEvaluationPage = lazy(() => import('./speaking/evaluate-part-2/[id]/modify/page'));
const IeltsSpeakingPart3_ModifyEvaluationPage = lazy(() => import('./speaking/evaluate-part-3/[id]/modify/page'));

export const IeltsRoutes: RouteObject[] = [
  {
    path: '/ielts',
    children: [
      {
        path: '/ielts/writing',
        children: [
          {
            path: '/ielts/writing/evaluate-task-1',
            Component: IeltsWritingTask1_EvaluatePage,
          },
          {
            path: '/ielts/writing/evaluate-task-1/:id',
            Component: IeltsWritingTask1_EvaluatePage,
          },
          {
            path: '/ielts/writing/evaluate-task-1/:id/modify',
            Component: IeltsWritingTask1_ModifyEvaluationPage,
          },
          {
            path: '/ielts/writing/evaluate-task-2',
            Component: IeltsWritingTask2_EvaluatePage,
          },
          {
            path: '/ielts/writing/evaluate-task-2/:id',
            Component: IeltsWritingTask2_EvaluatePage,
          },
          {
            path: '/ielts/writing/evaluate-task-2/:id/modify',
            Component: IeltsWritingTask2_ModifyEvaluationPage,
          },
        ],
      },
      {
        path: '/ielts/speaking',
        children: [
          {
            path: '/ielts/speaking/evaluate-part-1',
            Component: IeltsSpeakingPart1_EvaluatePage,
          },
          {
            path: '/ielts/speaking/evaluate-part-1/:id',
            Component: IeltsSpeakingPart1_EvaluatePage,
          },
          {
            path: '/ielts/speaking/evaluate-part-1/:id/modify',
            Component: IeltsSpeakingPart1_ModifyEvaluationPage,
          },
          {
            path: '/ielts/speaking/evaluate-part-2',
            Component: IeltsSpeakingPart2_EvaluatePage,
          },
          {
            path: '/ielts/speaking/evaluate-part-2/:id',
            Component: IeltsSpeakingPart2_EvaluatePage,
          },
          {
            path: '/ielts/speaking/evaluate-part-2/:id/modify',
            Component: IeltsSpeakingPart2_ModifyEvaluationPage,
          },
          {
            path: '/ielts/speaking/evaluate-part-3',
            Component: IeltsSpeakingPart3_EvaluatePage,
          },
          {
            path: '/ielts/speaking/evaluate-part-3/:id',
            Component: IeltsSpeakingPart3_EvaluatePage,
          },
          {
            path: '/ielts/speaking/evaluate-part-3/:id/modify',
            Component: IeltsSpeakingPart3_ModifyEvaluationPage,
          },
        ],
      },
    ],
  },
];
