import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import {
  Separator,
  SidebarCollapseButton,
  SidebarContent,
  SidebarLogo,
  SidebarNav,
  SidebarNavGroup,
  SidebarNavItem,
} from '@bluemarker/ui';
import { BookOpenCheckIcon, GalleryVerticalEndIcon, HomeIcon, LogOutIcon } from '@bluemarker/ui/icons';
import { Link, useLocation } from 'react-router-dom';

import useSignOut from '@/apis/authentication/use-sign-out';

import SidebarUserDropdown from './sidebar-user-dropdown';

const TRANSLATION: I18nTranslation = {
  en: {
    home: 'Home',
    history: 'History',
    evaluate: 'Evaluate',
    signOut: 'Sign out',
    help: 'Help',
  },
  vi: {
    home: 'Trang chủ',
    history: 'Lịch sử',
    evaluate: 'Đánh giá',
    help: 'Hỗ trợ',
    signOut: 'Đăng xuất',
  },
};

const PlaygroundSidebar = () => {
  const { pathname } = useLocation();

  const { t } = useExtendI18nTranslation(TRANSLATION);

  const { mutate: signOut } = useSignOut();

  return (
    <SidebarContent>
      <SidebarCollapseButton />

      <Link to="/">
        <SidebarLogo bluemarkerPlatform="Playground Platform" />
      </Link>

      <SidebarUserDropdown />

      <Separator className="mb-4" />

      <div className="flex flex-col flex-1 !mt-0">
        <div className="flex-1 space-y-2">
          <SidebarNav label={t('home')} Icon={HomeIcon} active={pathname === '/'} href="/" />

          <SidebarNav
            label={t('history')}
            Icon={GalleryVerticalEndIcon}
            active={pathname === '/history'}
            href="/history"
          />

          <SidebarNav label="IELTS" Icon={BookOpenCheckIcon} active={pathname.startsWith('/ielts')}>
            <SidebarNavGroup label="IELTS Writing">
              <SidebarNavItem
                active={pathname === '/ielts/writing/evaluate-task-1'}
                href="/ielts/writing/evaluate-task-1"
              >
                {t('evaluate')} Task 1
              </SidebarNavItem>
              <SidebarNavItem
                active={pathname === '/ielts/writing/evaluate-task-2'}
                href="/ielts/writing/evaluate-task-2"
              >
                {t('evaluate')} Task 2
              </SidebarNavItem>
            </SidebarNavGroup>
            <SidebarNavGroup label="IELTS Speaking">
              <SidebarNavItem
                active={pathname === '/ielts/speaking/evaluate-part-1'}
                href="/ielts/speaking/evaluate-part-1"
              >
                {t('evaluate')} Part 1
              </SidebarNavItem>
              <SidebarNavItem
                active={pathname === '/ielts/speaking/evaluate-part-2'}
                href="/ielts/speaking/evaluate-part-2"
              >
                {t('evaluate')} Part 2
              </SidebarNavItem>
              <SidebarNavItem
                active={pathname === '/ielts/speaking/evaluate-part-3'}
                href="/ielts/speaking/evaluate-part-3"
              >
                {t('evaluate')} Part 3
              </SidebarNavItem>
            </SidebarNavGroup>
          </SidebarNav>
        </div>
        <div className="space-y-2">
          <SidebarNav
            className="text-danger-4 hover:text-danger-4 hover:bg-danger-4/10"
            label={t('signOut')}
            Icon={LogOutIcon}
            active={false}
            handler={signOut}
          />
        </div>
      </div>
    </SidebarContent>
  );
};

export default PlaygroundSidebar;
