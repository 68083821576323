import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { cn } from '../../utils';
import { Button } from '../base';

export type SidebarNavItemProps = PropsWithChildren<{
  active: boolean;
  handler?: () => void;
  href?: string;
}>;

const SidebarNavItem = ({ active, children, handler, href }: SidebarNavItemProps) => {
  const button = (
    <Button
      className={cn(
        'relative text-neutral-7 duration-300 transition-all w-full justify-start',
        active && 'text-primary-4 bg-primary-0/50'
      )}
      onClick={handler}
      variant="link"
    >
      <span className="truncate">{children}</span>
    </Button>
  );

  return (
    <li className="w-full mb-1 last:mb-0">
      {href ? (
        <Link className="w-full" to={href}>
          {button}
        </Link>
      ) : (
        button
      )}
    </li>
  );
};

export default SidebarNavItem;
