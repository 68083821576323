import type { PropsWithChildren } from 'react';

import { cn } from '../../utils';
import { useSidebarContext } from './context';

export type SidebarNavGroupProps = PropsWithChildren<{
  label: string;
}>;

const SidebarNavGroup = ({ children, label }: SidebarNavGroupProps) => {
  const { isCollapsed } = useSidebarContext();

  return (
    <li className="mb-4 last:mb-0">
      <div
        className={cn(
          'font-extrabold text-xs tracking-wide text-neutral-7 duration-300 transition-all justify-start px-4 py-2 border-b border-neutral-2 mb-1',
          isCollapsed && 'px-0'
        )}
      >
        <p>{label}</p>
      </div>
      <ul>{children}</ul>
    </li>
  );
};

export default SidebarNavGroup;
