import { LoadingScreen } from '@bluemarker/ui';
import type { PropsWithChildren } from 'react';

import useQueryUserProfile from '@/apis/authentication/use-query-user-profile';

const AuthenticatedGuard = ({ children }: PropsWithChildren) => {
  const { isLoading, isError } = useQueryUserProfile();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    window.location.href = import.meta.env.VITE_ACCOUNT_PLATFORM_URL + '/' + 'playground';
    return <LoadingScreen />;
  }

  return children;
};

export default AuthenticatedGuard;
