import { initializeAxios } from '@bluemarker/core';

const API = initializeAxios({
  baseURL: import.meta.env.VITE_API_URL,
  authentication: {
    redirectURL: import.meta.env.VITE_ACCOUNT_PLATFORM_URL,
    subdomain: 'playground',
  },
});

export default API;
